import React, {createContext, useEffect, useState} from "react";

export const UserContext = createContext();

export const UserProvider = (props) => {
    const [token, setToken] = useState(localStorage.getItem("wedding_token"));
    const [userId, setUserId] = useState(localStorage.getItem("wedding_user_id"));
    const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:8000';

    useEffect(() => {
        const fetchUser = async () => {
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            }
            const response = await fetch(`${apiUrl}/users/me/`, requestOptions);
            if (!response.ok) {
                console.log("user login expired");
                localStorage.setItem("wedding_token", "null");
                localStorage.setItem("wedding_user_id", "null");
                setToken("null");
            }
            const data = await response.json();
            localStorage.setItem("wedding_token", token);
            localStorage.setItem("wedding_user_id", data.id);
            setUserId(data.id);
        };
        if (token) {
            fetchUser();
            }
    }, [token, userId]);
    return (
        <UserContext.Provider value={[token, setToken, userId, setUserId]}>
            {props.children}
        </UserContext.Provider>
    )

};

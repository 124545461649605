import React from "react";
import proposal from "../../media/proposal.gif";
import dunno from "../../media/I dunno.jpeg";
import ravello from "../../media/Ravello.jpeg";
import champagne from "../../media/Proposal champagne.jpeg"
import polaroid from "../../media/polaroid hands.jpeg";
import positano from "../../media/Positano.jpeg";
import welcomeHome from "../../media/welcome-home.jpg";
import vesuvius from "../../media/vesuvius.jpg";
import './SheSaidYes.css';

function SheSaidYesTab() {
    return (
        <div className="container has-text-centered pl-3 pr-3">
            <div className="is-size-5">
                <p>
                    On September the 7th 2022 whilst gazing over the calm waters of the Bay of Naples,
                    Aisha abandoned reason for madness and decided to marry me.
                </p>
                <br/>
                <p>
                    After four <s>stressful</s> days of looking for the perfect proposal spot, I finally <s>drank enough prosecco</s> mustered the courage to ask
                    her to be my wife.  It was the happiest moment of my life and I can't wait to spend the rest of it with her.
                </p>
                <br/>
                <p>
                    Now far be it from me to look a gift horse in the mouth, so in the spirit of making hay while the sun shines... <strong><i>we're getting married.</i></strong>
                </p>
            </div>
            <br/>

            <div className="tile is-ancestor">
                <div className="tile is-parent">
                    <article className="tile is-child box has-background-grey-light">
                        <img className="she-said-yes-images" src={ravello} alt="Ravello photo"/>
                        <p>Attempt three - the Ravello incident</p>
                    </article>
                </div>
                <div className="tile is-parent">
                    <article className="tile is-child box has-background-grey-light">
                        <img className="she-said-yes-images" src={champagne} alt="Champagne bucket photo"/>
                        <p>Liquid courage</p>
                    </article>
                </div>
                <div className="tile is-parent">
                    <article className="tile is-child box has-background-grey-light">
                        <img className="she-said-yes-images" src={proposal} alt="proposal gif"/>
                        <p>The successful attempt</p>
                    </article>

                </div>
            </div>

            <div className="tile is-ancestor">
                <div className="tile is-vertical is-8">
                    <div className="tile is-parent">
                        <article className="tile is-child box has-background-grey-light">
                            <img className="she-said-yes-images" src={dunno} alt="point at building?"/>
                            <p>Too wet to propose here</p>
                        </article>
                    </div>
                </div>
                <div className="tile is-vertical">
                    <div className="tile is-parent">
                        <article className="tile is-child box has-background-grey-light">
                            <img className="she-said-yes-images" src={polaroid} alt="polaroid hands"/>
                            <p>"Oh no I've got little boy fingers"</p>
                        </article>
                    </div>
                    <div className="tile is-parent">
                        <article className="tile is-child box has-background-grey-light">
                            <img className="she-said-yes-images" src={positano} alt="funny Aisha"/>
                            <p>Positano is too hilly and too touristy</p>
                        </article>
                    </div>
                </div>
            </div>
            <div className="tile is-ancestor">
                <div className="tile is-parent">
                    <article className="tile is-child box has-background-grey-light">
                        <img className="she-said-yes-images" src={vesuvius} alt="Vesuvius hike"/>
                        <p>Really glad I didn't try and propose on a hike up a volcano</p>
                    </article>
                </div>
                <div className="tile is-parent">
                    <article className="tile is-child box has-background-grey-light">
                        <img className="she-said-yes-images" src={welcomeHome} alt="Welcome Home Surprise"/>
                        <p>One final surprise when we arrived home</p>
                    </article>
                </div>
            </div>
            <br/>
        </div>
    )
}

export default SheSaidYesTab;

import React, {useContext, useEffect, useState} from "react";
import {UserContext} from "../../context/UserContext";
import ErrorMessage from "../ErrorMessage";

import loadingWheel from "../../media/Spinner-1s-200px.svg";

function Food() {
    const [token, setToken ,userId, ] = useContext(UserContext);
    const [FoodOptions, setFoodOptions] = useState("");
    const [fistCourse, setFistCourse] = useState(null);
    const [SecondCourse, setSecondCourse] = useState(null);
    const [thirdCourse, setThirdCourse] = useState(null);
    const [dietaryRequirements, setDietaryRequirements] = useState(null);
    const [MealsByCourse, setMealsByCourse] = useState({});
    const [hasRsvp, setHasRsvp] = useState(null);
    const [rsvp, setRsvp] = useState(null);
    const [error, setError] = useState(null);
    const [foodError, setFoodError] = useState(null);
    const [dietaryRequirementsError, setDietaryRequirementsError] = useState(null);
    const [mealsByCourseError, setMealsByCourseError] = useState(null);
    const [foodChoicesError, setFoodChoicesError] = useState(null);
    const [loading, setLoading] = useState(true);

    const BaseUrl = process.env.REACT_APP_API_URL || 'http://localhost:8000';

    const FoodChoicesUrl = `${BaseUrl}/food/meal-choices?guest_id=${userId}`;
    const DietaryRequirementsUrl = `${BaseUrl}/guest/dietary-requirements?_id=${userId}`;
    const MealsByCourseUrl = `${BaseUrl}/food/meals-by-course`;
    const RsvpUrl = `${BaseUrl}/guest/rsvp`;

    const postFoodChoice = async (e) => {
        e.preventDefault();
        if (!fistCourse || !SecondCourse || !thirdCourse) {
            console.error('One or more courses are not selected.');
            return;
        }
        const bodyArray = []
        const meals = [
            {'id': fistCourse.id, 'meal_type': 'first'},
            {'id': SecondCourse.id, 'meal_type': 'second'},
            {'id': thirdCourse.id, 'meal_type': 'third'}
        ];
        console.log(meals)
        const mealsBody = {'guest_id': userId, 'meals': meals};
        // append meals body to bodyArray
        bodyArray.push(mealsBody)
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(bodyArray)
        }
        const response = await fetch(FoodChoicesUrl, requestOptions);
        if (!response.ok) {
            setFoodChoicesError(`Error submitting food choices ${response.detail}`);
            console.log(response);
            if (response.status === 401) {
                setToken('null');
            }
            return;
        }
        setFoodOptions(`You selected ${fistCourse.name} to start, with ${SecondCourse.name} as a main, and a pudding of ${thirdCourse.name}`);
    }

    const updateDietaryRequirements = async (e) => {
        e.preventDefault();

        let requestOptions;
        if (dietaryRequirements === "") {
            requestOptions = {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            }
        } else {
            requestOptions = {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({id: userId, dietary_requirements: dietaryRequirements})
            }
        }

        const response = await fetch(DietaryRequirementsUrl, requestOptions);
        if (!response.ok) {
            setDietaryRequirementsError(`Error updating dietary requirements ${response.detail}`);
            console.log(response);
            if (response.status === 401) {
                setToken('null');
            }
            return;
        }
        alert('Your dietary requirements have been successfully updated')
        const data = await response.json();
        let reqs = data.dietary_requirements
        if (requestOptions.method === "DELETE") {
            reqs = null
        }
        setDietaryRequirements(reqs);
    }

    useEffect(() => {
        const fetchRsvp = async () => {
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            }
            const response = await fetch(RsvpUrl + `?_id=${userId}`, requestOptions);
            if (response.status === 404) {
                setHasRsvp(false);
                return;
            }
            if (!response.ok) {
                setError('Error fetching RSVP');
                if (response.status === 401) {
                    setToken('null');
                }
                return;
            }
            const data = await response.json();
            setHasRsvp(true);
            setRsvp(data.is_attending);
        }

        const FetchFoodRelatedData = async () => {

            const fetchDietaryRequirements = async () => {
                const requestOptions = {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                }
                const response = await fetch(DietaryRequirementsUrl, requestOptions);
                if (!response.ok) {
                    setDietaryRequirementsError('Error fetching dietary requirements');
                    if (response.status === 401) {
                        setToken('null');
                    }
                    return;
                }
                const data = await response.json();
                setDietaryRequirements(data.dietary_requirements);
            }

            const getFood = async () => {
                try {
                    const requestOptions = {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`
                        }
                    };
                    const response = await fetch(FoodChoicesUrl, requestOptions);
                    if (response.status === 404 || response.status === 403) {
                        console.log("no food or rsvp")
                        console.log(response)
                        setLoading(false);
                        return
                    } else if (!response.ok) {
                        setFoodError('Error fetching food choices');
                        setLoading(false);
                        if (response.status === 401) {
                            setToken('null');
                        }
                        return;
                    }
                    const data = await response.json();
                    setLoading(false);
                    if (data && data.meals && data.meals.length > 0) {
                        const firstCourse = data.meals.find(meal => meal.meal_type === 'first');
                        const secondCourse = data.meals.find(meal => meal.meal_type === 'second');
                        const thirdCourse = data.meals.find(meal => meal.meal_type === 'third');
                        setFistCourse(firstCourse);
                        setSecondCourse(secondCourse);
                        setThirdCourse(thirdCourse);
                        setFoodOptions(`You have selected ${firstCourse.name} to start, with ${secondCourse.name} as a main, and a pudding of ${thirdCourse.name}`);
                    }
                } catch (error) {
                    setFoodError('Error fetching food choices');
                    setLoading(false);
                }
            };

            const fetchMealsByCourse = async () => {
                try {
                    const requestOptions = {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`
                        }
                    };
                    const response = await fetch(MealsByCourseUrl, requestOptions);
                    if (!response.ok) {
                        setMealsByCourseError('Error fetching meals by course');
                        if (response.status === 401) {
                            setToken('null');
                        }
                        return;
                    }
                    const data = await response.json();
                    setMealsByCourse(data);
                } catch (error) {
                    setMealsByCourseError('Error fetching meals by course');
                }
            };

            try {
                console.log('geting food data')
                await fetchRsvp();
                await getFood();
                await fetchMealsByCourse();
                await fetchDietaryRequirements();
            } catch (error) {
                console.error("Error fetching food and meals:", error);
            }
        };

        FetchFoodRelatedData();
    }, []);

    console.log(hasRsvp, rsvp);
return (
    <div className="block content has-text-centered is-centred is-mobile pl-3 pr-3">
        <h1>Food</h1>
        {
            loading ? (
                    <img src={loadingWheel} alt="loading wheel"/>
                ) :
            hasRsvp === false ? (
            <div>
                <h2>Please RSVP before submitting food preferences</h2>
            </div>
        ) : (
            rsvp === false ? (
                <div>
                    <h2>Sorry you can't come, please let us know if you change your mind</h2>
                </div>
            ) : (
                FoodOptions === "" ? (
                    <div>
                        <h2>Choose your meal</h2>
                        <p className="is-size-5 is-italic">Please enter any dietary requirements in the text box below so your meals can be adjusted by the chefs accordingly</p>
                        <form onSubmit={postFoodChoice}>
                            <div className="field">
                                <label className="label">First Course</label>
                                <div className="control">
                                    <div className="select">
                                        <select onChange={(e) => {
                                            const selectedCourseId = parseInt(e.target.value);
                                            const selectedCourse = MealsByCourse.first_courses.find(course => course.id === selectedCourseId);
                                            setFistCourse(selectedCourse);
                                            console.log("Selected first course:", selectedCourse);
                                        }}>
                                            <option className="has-text-centered">Select a first course</option>
                                            {MealsByCourse && MealsByCourse.first_courses && MealsByCourse.first_courses.map((course) => {
                                                return <option className="has-text-centered" key={course.id} value={course.id}>{course.name} - {course.description}</option>;
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="field">
                                <label className="label">Second Course</label>
                                <div className="control">
                                    <div className="select">
                                        <select onChange={(e) => {
                                            const selectedCourseId = parseInt(e.target.value);
                                            const selectedCourse = MealsByCourse.second_courses.find(course => course.id === selectedCourseId);
                                            setSecondCourse(selectedCourse);
                                            console.log("Selected second course:", selectedCourse);
                                        }}>
                                            <option className="has-text-centered">Select a second course</option>
                                            {MealsByCourse && MealsByCourse.second_courses && MealsByCourse.second_courses.map((course) => {
                                                return <option className="has-text-centered" key={course.id} value={course.id}>{course.name} - {course.description}</option>;
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="field">
                                <label className="label">Third Course</label>
                                <div className="control">
                                    <div className="select">
                                        <select onChange={(e) => {
                                            const selectedCourseId = parseInt(e.target.value);
                                            const selectedCourse = MealsByCourse.third_courses.find(course => course.id === selectedCourseId);
                                            setThirdCourse(selectedCourse);
                                            console.log("Selected third course:", selectedCourse);
                                        }}>
                                            <option className="has-text-centered">Select a third course</option>
                                            {MealsByCourse && MealsByCourse.third_courses && MealsByCourse.third_courses.map((course) => {
                                                return <option className="has-text-centered" key={course.id} value={course.id}>{course.name} - {course.description}</option>;
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <button className="button is-small is-link" type="submit">Submit</button>
                        </form>
                    </div>
                ) : (
                    <div>
                        <h2>Your meal choices</h2>
                        <p className="is-size-5">You selected the following meals</p>
                        <p className="is-size-4 has-text-weight-bold is-underlined mb-1">First Course</p>
                        <p className="is-size-5"><strong>{fistCourse.name}</strong> - <i>{fistCourse.description}</i></p>
                        <p className="is-size-4 has-text-weight-bold is-underlined mb-1">Second Course</p>
                        <p className="is-size-5"><strong>{SecondCourse.name}</strong> - <i>{SecondCourse.description}</i></p>
                        <p className="is-size-4 has-text-weight-bold is-underlined mb-1">Third Course</p>
                        <p className="is-size-5"><strong>{thirdCourse.name}</strong> - <i>{thirdCourse.description}</i></p>
                        <br/>
                        <p className="is-size-5 is-italic">Please enter any dietary requirements in the text box below so your meals can be adjusted by the chefs accordingly</p>
                    </div>
                )
            )
        )}
        {
            hasRsvp === true && rsvp === true && (
                <div className="block content has-text-centered is-centred is-mobile">
                    <br/>
                    <h2>Dietary Requirements</h2>
                    <div className="columns is-centered">
                        <div className="column is-one-quarter">
                            <form onSubmit={updateDietaryRequirements}>
                                <div className="field">
                                    <div className="control is-expanded">
                                        <textarea className="textarea" value={dietaryRequirements} onChange={(e) => setDietaryRequirements(e.target.value)} />
                                    </div>
                                    <button className="button is-small is-link mt-3" type="submit">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            )
        }
        <ErrorMessage message={error} />
        <ErrorMessage message={foodError} />
        <ErrorMessage message={dietaryRequirementsError} />
        <ErrorMessage message={mealsByCourseError} />
        <ErrorMessage message={foodChoicesError} />

        </div>
    )
}

export default Food;
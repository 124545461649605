import React, { useState } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import './WeddingTabs.css';
import HomeTab from "./tabs/HomeTab";
import SheSaidYesTab from "./tabs/SheSaidYes";
import GettingThere from "./tabs/GettingThere";
import Gifts from "./tabs/Gifts";
import RSVP from "./tabs/RSVP";
import Food from "./tabs/Food";
import MusicTable from "./tabs/music/Music";

function WeddingTabs() {
    const [activeTab, setActiveTab] = useState('Home');

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
    };

    return (
        <div>
            <div className="tabs is-centered custom-tabs">
                <ul>
                    <li className={activeTab === 'Home' ? 'is-active' : ''}>
                        <a onClick={() => handleTabClick('Home')}>
                            <span className="icon is-small"><i className="fa-solid fa-house" aria-hidden="true"></i></span>
                            <span>Home</span>
                        </a>
                    </li>
                    <li className={activeTab === 'She Said Yes' ? 'is-active' : ''}>
                        <a onClick={() => handleTabClick('She Said Yes')}>
                            <span className="icon is-small"><i className="fa-solid fa-heart" aria-hidden="true"></i></span>
                            <span>She Said Yes</span>
                        </a>
                    </li>
                    <li className={activeTab === 'RSVP' ? 'is-active' : ''}>
                      <a onClick={() => handleTabClick('RSVP')}>
                          <span className="icon is-small"><i className="fa-solid fa-envelope" aria-hidden="true"></i></span>
                          <span>RSVP</span>
                      </a>
                      </li>
                      <li className={activeTab === 'Food' ? 'is-active' : ''}>
                          <a onClick={() => handleTabClick('Food')}>
                              <span className="icon is-small"><i className="fa-solid fa-utensils" aria-hidden="true"></i></span>

                              <span>Food</span>
                          </a>
                      </li>
                      <li className={activeTab === 'Getting There' ? 'is-active' : ''}>
                          <a onClick={() => handleTabClick('Getting There')}>
                              <span className="icon is-small"><i className="fa-solid fa-map" aria-hidden="true"></i></span>

                              <span>Getting There</span>
                          </a>
                      </li>
                      <li className={activeTab === 'Music' ? 'is-active' : ''}>
                          <a onClick={() => handleTabClick('Music')}>
                              <span className="icon is-small"><i className="fa-solid fa-music" aria-hidden="true"></i></span>
                              <span>Music</span>
                          </a>
                      </li>
                      <li className={activeTab === 'Gifts' ? 'is-active' : ''}>
                          <a onClick={() => handleTabClick('Gifts')}>
                              <span className="icon is-small"><i className="fa-solid fa-gift" aria-hidden="true"></i></span>
                              <span>Gifts</span>
                          </a>
                      </li>
                </ul>
            </div>
            <div className="tab-content">
                <TransitionGroup>
                  <CSSTransition key={activeTab} classNames="fade" timeout={800}>
                      <div className="tab-pane">
                        {activeTab === 'Home' && <HomeContent />}
                        {activeTab === 'She Said Yes' && <SheSaidYesContent />}
                        {activeTab === 'RSVP' && <RSVPContent />}
                        {activeTab === 'Food' && <FoodContent />}
                        {activeTab === 'Getting There' && <GettingThereContent />}
                        {activeTab === 'Music' && <MusicContent />}
                        {activeTab === 'Gifts' && <GiftsContent />}
                      </div>
                  </CSSTransition>
                </TransitionGroup>
            </div>
        </div>
    );
}

// Define content components for each tab
function HomeContent() {
    return <HomeTab />;
}

function SheSaidYesContent() {
    return <SheSaidYesTab/>;
}

function RSVPContent() {
    return <RSVP/>;
}

function FoodContent() {
    return <Food/>;
}

function GettingThereContent() {
    return <GettingThere/>;
}

function MusicContent() {
    return <MusicTable/>;
}

function GiftsContent() {
    return <Gifts/>;
}


export default WeddingTabs;

import React, {useContext} from 'react';
import Login from './components/Login';
import WeddingPage from './components/WeddingPage';
import {UserContext} from "./context/UserContext";

function App() {
    const [token,] = useContext(UserContext);
  return (
      <div className="App">
          {
                !token || token === "null" ? (
                    <Login />
                ) : (
                    <WeddingPage />
                )
          }
      </div>
  );
}

export default App;
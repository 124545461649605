import React from "react";
import OrderOfDay from "../../media/Alex & Aisha 2024.svg";

function HomeTab() {
    return (
        <div className="container has-text-centered pl-3 pr-3">
            <h1 className="is-size-2 mb-4">Welcome To Our Wedding Website</h1>
            <div className="is-size-5">
                <p>
                    We are so excited to share our special day with you.
                    Please take a few moments to scroll through the various tabs to learn about the wedding :)
                </p>
                <br/>
                <p>
                    We would like all guests to have RSVP'd by the 1st of July 2024.  Don't forget to pick your meal options and submit a song request.
                </p>
                <br/>
                <p>
                    Information about accommodation will be shown upon completion of the RSVP form.
                </p>
                <br/>
                <p>
                    We really hope you can make it, can't wait to see you there!
                </p>
            </div>
            <br/>
            <h1 className="is-size-2 mb-4">Order Of The Day</h1>
            <img src={OrderOfDay} alt="Order Of Day"/>
        </div>
    )
}

export default HomeTab;

import React from "react";
import paypal from "../../media/PP paypal.png";

function Gifts() {
    return (
        <div className="container has-text-centered pl-3 pr-3">
            <h1 className="is-size-2 mb-4">Gifts</h1>
            <div className="is-size-5">
                <p>
                    To us, your presence is present enough.
                </p>
                <br/>
                <p>
                    However, if you would like to give a gift, we would be very grateful for a contribution towards our honeymoon.
                </p>
                <br/>
                <p>
                    We are planning to go to Japan and any contribution would help considerably.
                </p>
                <br/>
                <p>
                    If you do decide to contribute to the honeymoon fund, you can do so via PayPal using the link below:
                </p>
                <br/>

                <div className="block is-flex is-justify-content-center">
                    <figure className="image is-128x128">
                        <a href="https://paypal.me/chr1sto7?country.x=GB&locale.x=en_GB" target="_blank" rel="noopener noreferrer">
                            <img src={paypal} alt="PayPal" />
                        </a>
                    </figure>
                </div>
                <br/>
                <p>
                    Or via bank transfer to:<br/><br/>
                    <strong>Account Name:</strong> ALEXANDER CHRISTOPHERSEN<br/>
                    <strong>Account Number:</strong> 53330761<br/>
                    <strong>Sort Code:</strong> 20-30-89<br/>

                </p>
                <br/>
                <p>
                    Thank you so much for your generosity.
                </p>
                <br/>
                <br/>
            </div>
        </div>
    )
}

export default Gifts;
import React from 'react';
import CountdownClock from "./CountdownClock";
import WeddingTabs from "./WeddingTabs";
import './WeddingPage.css'

function WeddingPage() {
    const weddingDate = '2024-08-30T12:00:00';

  return (
      <div
           className="wedding-page">
          <br/>
          <br/>
          <div className="block">
                <h1 className="has-text-centered title"
                    style={{fontFamily: 'Xandria', fontSize: '5em', color: 'darkslategray'}}>
                    <b>Alex & Aisha</b>
                </h1>
            </div>
          <div className="block">
              <h1 className="has-text-centered"
                  style={{fontFamily: 'Xandria', fontSize: '3em', color: 'darkslategray'}}>
                  <b>Married in...</b>
              </h1>
          </div>

            <CountdownClock weddingDate={weddingDate}/>

          <br/>
          <br/>

            <WeddingTabs/>
      </div>
  );
}

export default WeddingPage;

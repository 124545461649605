import React from "react";

function GettingThere() {
    return (
        <div className="container content pl-3 pr-3">
            <div className="container content has-text-centered">
                <h1 className="is-size-2 mb-4">Getting to Wasing Park</h1>
                <div className="block">
                    <p className="is-size-4">
                        Wasing Park, Wasing Ln, Aldermaston, Reading, RG7 4NG
                    </p>
                </div>
                <div className="block" style={{ maxWidth: "800px", margin: "0 auto" }}>
                    <div style={{ paddingBottom: "56.25%", position: "relative", height: 0 }}>
                        <iframe
                            title="Wasing Park"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2490.5070254119237!2d-1.1774578882365756!3d51.37535882022371!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4876a050d6743735%3A0x46dbdba87923f838!2sWasing%20Park!5e0!3m2!1sen!2suk!4v1686859809479!5m2!1sen!2suk"
                            width="100%" height="100%" style={{ border: "0", position: "absolute", top: 0, left: 0 }}
                            allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"
                        />
                    </div>
                </div>
            </div>

            <br />

            <div className='columns is-centered is-mobile is-9'>
                <div className='column is-2-mobile is-1-desktop'>
                    <span className="icon-text">
                      <span className="icon">
                        <i className="fas fa-car"></i>
                      </span>
                      <span className="is-size-5">Car</span>
                    </span>
                </div>
                <div className='column is-half'>
                        <p className="is-size-5">
                            Getting to Wasing Park by car is relatively easy.  In our previous trips, both google maps
                            and a relatively old satnav had no issue finding it when using either the venue name or the
                            postcode.
                            <br></br>
                            <br></br>
                            Aldemaston village is pretty small and there is good signage to help out.  The
                            only notable quirk is that there are two entrances to the estate.  <strong className="has-text-primary">
                            The main entrance is just off Wasing Lane
                            </strong> and is the one you should use.
                            <strong className="has-text-danger">  Do not use the entrance off Back Lane</strong>.
                        </p>
                </div>
            </div>

            <br/>

            <div className='columns is-centered is-mobile is-9'>
                <div className='column is-2-mobile is-1-desktop'>
                        <span className="icon-text">
                          <span className="icon">
                            <i className="fas fa-train"></i>
                          </span>
                          <span className="is-size-5">Train</span>
                        </span>
                </div>
                <div className='column is-half is-size-5'>
                    The best stations for getting to Wasing Park are Aldermaston or Midgham.  There are a few direct
                    trains which run from London Paddington, but most will require a change at Reading.  Trains run quite
                    frequently and typically take between 45 minutes to an hour.  Both stations are about a 10 minute
                    drive from the venue so I would recommend booking a taxi or arranging a lift.
                </div>
            </div>

            <br/>

            <div className='columns is-centered is-mobile is-9'>
                <div className='column is-2-mobile is-1-desktop'>
                        <span className="icon-text">
                          <span className="icon">
                            <i className="fas fa-taxi"></i>
                          </span>
                          <span className="is-size-5">Taxi</span>
                        </span>
                </div>
                <div className='column is-half is-size-5'>
                    If you are not staying on-site, we recommend booking taxis to arrive at 00:45.  Please see the below
                    set of companies provided to us by the Wasing team <i>(we suggest booking these in advance)</i>.<br/><br/>

                    <u><strong>Companies in Reading</strong><br/></u>
                    500 Cars<br/>
                    <a href="callto:01189599999">0118 959 9999</a><br/>
                    (Taxi, minibus and private hire available)<br/><br/>

                    Triple A Cars<br/>
                    <a href="callto:01189504030">0118 950 4030</a><br/><br/>

                    <u><strong>Companies in Newbury</strong></u><br/>
                    Cabco<br/>
                    <a href="callto:0163533333">01635 33333</a><br/><br/>

                    Newcab<br/>
                    <a href="callto:0163544444">01635 44444</a><br/><br/>

                    Kennett and Avon Cars<br/>
                    <a href="callto:01635847853">01635 847853</a><br/>

                    <a href="mailto:info@kenav.com">info@kenav.com</a><br/>
                    <a href="https://www.kenav.com/" target="_blank" rel="noopener noreferrer">www.kenav.com</a><br/><br/>

                    <strong><u>Companies in Theale</u></strong><br/>
                    A4 Executive Cars<br/>
                    <a href="callto:01189108108">0118 910 8108</a><br/>
                    <a href="mailto:bookings@a4car.co.uk">bookings@a4car.co.uk</a><br/>
                    <a href="https://www.a4car.co.uk/" target="_blank" rel="noopener noreferrer">www.a4car.co.uk</a><br/><br/>
                </div>
            </div>
            <br/>
        </div>
    )
}

export default GettingThere;

import React, {useContext, useEffect, useState} from "react";
import {UserContext} from "../../context/UserContext";
import ErrorMessage from "../ErrorMessage";

import loadingWheel from "../../media/Spinner-1s-200px.svg";

function RSVP() {
    const [token, setToken ,userId, ] = useContext(UserContext);
    const [hasRsvp, setHasRsvp] = useState(null);
    const [rsvp, setRsvp] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:8000';

    const RsvpUrl = `${apiUrl}/guest/rsvp`;


    const handleRsvp = async (e) => {
        e.preventDefault();
        const boolRsvp = rsvp === 'true';  // convert string to boolean
        const requestOptions = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({id: userId, is_attending: boolRsvp})
        }
        const response = await fetch(RsvpUrl, requestOptions);
        if (!response.ok) {
            setError(`Error submitting RSVP ${response.detail}`);
            setToken('null');
            console.log(response);
            return;
        }
        setHasRsvp(true);
        setRsvp(boolRsvp);
    }

    useEffect(() => {
        const fetchRsvp = async () => {
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            }
            const response = await fetch(RsvpUrl + `?_id=${userId}`, requestOptions);
            if (response.status === 404) {
                setHasRsvp(false);
                setLoading(false);
                return;
            }
            if (!response.ok) {
                setError('Error fetching RSVP');
                setLoading(false);
                if (response.status === 401) {
                    setToken('null');
                }
                return;
            }
            const data = await response.json();
            setHasRsvp(true);
            setRsvp(data.is_attending);
            setLoading(false);

        }
        if (hasRsvp === null) {
            fetchRsvp();
        }
    }, [hasRsvp, token, userId]);

    return (
        <div className="block content has-text-centered is-centred is-mobile pl-3 pr-3">
            {
                loading ? (
                    <img src={loadingWheel} alt="loading wheel"/>
                ) :
                !hasRsvp ? (
                    <div>
                        <h1>RSVP</h1>
                        <p className="is-size-6"> <i>Reminder the wedding is on Friday the 30th of August 2024 and you cannot RSVP on behalf of other guests</i></p>
                        <form onSubmit={handleRsvp}>
                            <div className="field">
                                <label className="label is-size-5">We ask that all guests RSVP by no later than the 1st of July</label>
                                <div className="control">
                                    <label className="radio mb-2">
                                        <input type="radio" name="is_attending" value="true" onChange={(e) => setRsvp(e.target.value)} />
                                        {' '}I will be attending
                                    </label>
                                    <br/>
                                    <label className="radio">
                                        <input type="radio" name="is_attending" value="false" onChange={(e) => setRsvp(e.target.value)} />
                                        {' '}I will not be attending
                                    </label>
                                </div>
                            </div>
                            <button className="button is-small is-link" type="submit">Submit</button>
                        </form>
                            <ErrorMessage message={error} />
                    </div>
                ) : (
                    rsvp === true ? (
                    <div>
                        <h1>That's great!</h1>
                        <div className="is-size-5">
                            <p>We look forward to seeing you there :)</p>
                            <h2 className="is-size-4">The night of the wedding</h2>
                            <p>
                                We have a certain number of rooms available to book on site which can found <a href="https://direct-book.com/properties/wasingparkdirect?locale=en&items[0][adults]=2&items[0][children]=0&items[0][infants]=0&currency=GBP&checkInDate=2024-08-30&checkOutDate=2024-08-31&trackPage=no&promocode=300824CHRISTOPHERSEN" target="_blank" rel="noopener noreferrer">
                                     <b>here</b>
                                </a>.  Rooms can have additional beds added at a £25 per person supplement and includes breakfast.</p>
                            <p>
                                Alternative accommodation can be found at the <a href="https://www.ihg.com/holidayinn/hotels/gb/en/find-hotels/select-roomrate?qDest=Reading,%20United%20Kingdom&qPt=CASH&qCiD=30&qCoD=31&qCiMy=72024&qCoMy=72024&qAdlt=2&qChld=0&qRms=1&qIta=99618783&qRtP=6CBARC&qAAR=6CBARC&qSlH=REAWS&srb_u=1&qSrt=sBR&qBrs=6c.hi.ex.sb.ul.ic.cp.cw.in.vn.cv.rs.ki.ki.ma.sp.va.sp.re.vx.nd.sx.we.lx.rn.sn.sn.sn.sn.sn&qWch=0&qSmP=0&qRad=30&qRdU=mi&setPMCookies=false&qpMbw=0&qErm=false&qpMn=0&qRmFltr=" target="_blank" rel="noopener noreferrer">
                                <b>Holiday Inn - Aldermaston Wharf</b></a>. &nbsp; We recommend booking early to avoid disappointment.
                            </p>
                            <br/>
                            <h2 className="is-size-4">The night before the wedding</h2>
                            <p>
                                We would love for you to join us the evening before the wedding for a final un-married meal in <a href="https://www.hindsheadaldermaston.co.uk/" target="_blank" rel="noopener noreferrer"><b>The Hinds Head - Aldermaston</b></a>.
                                <br/>The hotel have very kindly reserved 15 rooms for our wedding guests at a rate of £129 for a "Comfy Room", £149 for an "Indulgent Room", and £169 for a Junior suite (all rooms include breakfast).  <br/>If you would like to book one of these rooms, please let one of us know and we will make the arrangements.
                            </p>
                        </div>
                    </div>
                ) : (
                    <div>
                        <h1>Thank you for letting us know</h1>
                        <p>We will miss you!</p>
                        <p>If you change your mind, please let us know so we can update your RSVP response</p>
                    </div>
                    )
                )
            }
            <br/>
        </div>
    )
}

export default RSVP;
import React, {useContext, useState} from 'react';
import './Login.css'; // Import CSS file for styling
import '../index.css'
import wasingPicture from '../media/wasing.jpg'
import {UserContext} from "../context/UserContext";
import ErrorMessage from "./ErrorMessage";


function Login() {
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [password, setPassword] = useState('');
  const [loginAttempt, setLoginAttempt] = useState(false);
  const [error, setError] = useState(null);
  const [, setToken, ,setUserId] = useContext(UserContext)

  const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:8000';

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoginAttempt(true);
    let pattern = '^[a-zA-Z]*$\n'
    if (firstname.match(pattern) || lastname.match(pattern)) {
        setError('First and last name must only contain letters');
        return;
    }
    let username = firstname.trim() + '.' + lastname.trim();
    username = username.toLowerCase();
    let requestBody = `grant_type=&username=${username}&password=${password}&scope=&client_id=&client_secret=`
    const response = await fetch(`${apiUrl}/token`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: JSON.stringify(requestBody)
    });
    if (!response.ok) {
      console.log("login failed");
      console.log(response);
      setError('Login failed');
      setLoginAttempt(false);
      return;
    }
    const data = await response.json();
    setToken(data.access_token);
    setUserId(data.id);
    setLoginAttempt(false);
  };

  return (
    <div className="login-outer">
      <img src={wasingPicture} alt="Wasing Park" className="login-image" />
      <div className="login-container">
        <h1 style={{ fontFamily: 'Xandria', fontSize: '5em', color: 'darkslategray' }}><b>Alex & Aisha</b></h1>
        <form onSubmit={handleLogin}>
          <div className="input-container">
            <input required className="input is-small" type="text" placeholder = "First Name" value={firstname} onChange={(e) => setFirstname(e.target.value)} />
          </div>
          <div className="input-container">
            <input required className="input is-small" type="text" placeholder = "Last Name" value={lastname} onChange={(e) => setLastname(e.target.value)} />
          </div>
          <div className="input-container">
            <div className="field">
              <p className="control has-icons-left">
            <input required className="input is-small" type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
            <span className="icon is-small is-left">
              <i className="fas fa-lock"></i>
            </span>
              </p>
            </div>
          </div>
          <ErrorMessage message={error} />
          {loginAttempt && <progress className="progress is-small is-primary" max="100" />}
          <button className="button is-small is-primary" type="submit">Login</button>
        </form>
      </div>
    </div>
  );
}

export default Login;

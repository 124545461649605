import React, { useState, useEffect } from 'react';

import './CountdownClock.css';

function CountdownClock({ weddingDate }) {
    useEffect(() => {
        const calculateCountdown = () => {
            const currentDate = new Date();
            const difference = new Date(weddingDate) - currentDate;

            if (difference > 0) {
                const days = Math.floor(difference / (1000 * 60 * 60 * 24));
                const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
                const seconds = Math.floor((difference % (1000 * 60)) / 1000);

                setCountdown({ days, hours, minutes, seconds });
            }
        };

        const countdownInterval = setInterval(calculateCountdown, 1000);

        return () => {
            clearInterval(countdownInterval);
        };
    }, [weddingDate]); // Include weddingDate in the dependency array

    const [countdown, setCountdown] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });

    const formatTime = (time) => {
        return time < 10 ? `0${time}` : time;
    };

    return (
        <div className="countdown-clock">
            <div className="countdown-box">
                <div className="countdown-box-content">
                    <span className="is-size-4">{formatTime(countdown.days)}</span>
                    <span className="is-size-7">Days</span>
                </div>
            </div>
            <div className="countdown-box">
                <div className="countdown-box-content">
                    <span className="is-size-4">{formatTime(countdown.hours)}</span>
                    <span className="is-size-7">Hours</span>
                </div>
            </div>
            <div className="countdown-box">
                <div className="countdown-box-content">
                    <span className="is-size-4">{formatTime(countdown.minutes)}</span>
                    <span className="is-size-7">Minutes</span>
                </div>
            </div>
            <div className="countdown-box">
                <div className="countdown-box-content">
                    <span className="is-size-4">{formatTime(countdown.seconds)}</span>
                    <span className="is-size-7">Seconds</span>
                </div>
            </div>
        </div>
    );
}

export default CountdownClock;

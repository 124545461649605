import React, {useContext, useEffect, useState} from "react";

import ErrorMessage from "../../ErrorMessage";
import MusicModal from "./MusicModal";
import {UserContext} from "../../../context/UserContext";

import loadingWheel from "../../../media/Spinner-1s-200px.svg";

function Table() {
    const [token, setToken , userId,] = useContext(UserContext);
    const [music, setMusic] = useState([])
    const [hasRsvp, setHasRsvp] = useState(null);
    const [rsvp, setRsvp] = useState(null);
    const [error, setError] = useState("")
    const [loaded, setLoaded] = useState(false)
    const [activeModal, setActiveModal] = useState(false)
    const [loading, setLoading] = useState(true)

    const BaseUrl = process.env.REACT_APP_API_URL || 'http://localhost:8000';
    const RsvpUrl = `${BaseUrl}/guest/rsvp`;
    const MusicUrl = `${BaseUrl}/music/?_id=`;  // hindsight - this route is confusing as the get takes a userId and the delete takes a music Id...

    const deleteMusic = async (id) => {
        const requestOptions = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        }
        let requestUrl = MusicUrl + id;
        const response = await fetch(requestUrl, requestOptions);

        if (!response.ok) {
            setError('Failed to delete song request');
            if (response.status === 401) {
                setToken('null');
            }
        }
        getMusic();
    }

    const getMusic = async () => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        }
        let requestUrl = MusicUrl + userId;
        const response = await fetch(requestUrl, requestOptions);

        if (response.status === 404) {
            setMusic([])
            setLoaded(true)
            return;
        }

        else if (!response.ok) {
            setError('Error fetching previous music choices');
            if (response.status === 401) {
                setToken('null');
            }
            return;
        }
        const data = await response.json();
        setMusic(data);
        setLoaded(true);
    }

    const fetchRsvp = async () => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }
        const response = await fetch(RsvpUrl + `?_id=${userId}`, requestOptions);
        if (response.status === 404) {
            setHasRsvp(false);
            setLoading(false);
            return;
        }
        if (!response.ok) {
            setError('Error fetching RSVP');
            setLoading(false);
            if (response.status === 401) {
                setToken('null');
            }
            return;
        }
        const data = await response.json();
        setHasRsvp(true);
        setRsvp(data.is_attending);
        setLoading(false);
    }

    useEffect(() => {
        fetchRsvp()
        getMusic()
    }, []);

    const handleModal = () => {
        setActiveModal(!activeModal);
        getMusic();
    }


    return (
        <div className="block content has-text-centered is-centred is-mobile pl-3 pr-3">
            <h1>Your Song Requests</h1>
            <br/>
            {
                loading ? (
                    <img src={loadingWheel} alt="loading wheel"/>
                ) :
                hasRsvp === false ? (
                <div>
                    <h2>Please RSVP before submitting song requests</h2>
                </div>
            ) : (
                rsvp === false ? (
                    <div>
                        <h2>Sorry you can't come, please let us know if you change your mind</h2>
                    </div>
                ) : (
                    <div className="columns is-centered">
                        <div className="column is-two-thirds">
                            <MusicModal
                                active={activeModal}
                                handleModal={handleModal}
                                token={token}
                                setToken={setToken}
                                setError={setError}
                                guestId={userId}
                            >

                            </MusicModal>
                            <button className="button is-fullwidth is-link mb-5" onClick={() => setActiveModal(true)}>Request Song</button>
                            <ErrorMessage message={error}/>
                            {
                                loaded && music ? (
                                    <table className="table is-half">
                                        <thead>
                                        <tr>
                                            <th>Artist</th>
                                            <th>Song</th>
                                            <th>Delete Request?</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {music.map((m) => (
                                            <tr key={m.id}>
                                                <td>{m.artist}</td>
                                                <td>{m.song}</td>
                                                <td>
                                                    <button className="button is-centered is-danger is-light" onClick={ () => deleteMusic(m.id) }>
                                                        Delete
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                ) : (<p>Loading song requests...</p>)
                            }
                        </div>
                    </div>
                )
            )
            }
        </div>
    )

}

export default Table;


import React, {useState} from "react";

const MusicModal = ({active, handleModal, token, setToken, setError, guestId}) => {
    const [artist, setArtist] = useState("");
    const [song, setSong] = useState("");
    const [inputError, setInputError] = useState("");

    const BaseUrl = process.env.REACT_APP_API_URL || 'http://localhost:8000';
    const MusicUrl = `${BaseUrl}/music/`;

    const cleanFormData = () => {
        setArtist("")
        setSong("")
    }

    const handleCreateMusic = async (e) => {
        e.preventDefault()
        if (artist ==="" || song === "") {
            setInputError("Both 'Artist' and 'Song' fields must be set")
            return;
        }
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({"guest_id": guestId, "artist": artist, "song": song})
        }
        const response = await fetch(MusicUrl, requestOptions);

        if (!response.ok) {
            if (response.status === 401) {
                setToken('null');
            }
            setError('Error submitting song request');
        } else {
            setInputError("")
            cleanFormData();
            handleModal();
        }

    }

    return(
        <div className={`modal ${active && "is-active pl-3 pr-3"}`}>
            <div className="modal-background" onClick={handleModal}></div>
            <div className="modal-card">
                <header className="modal-card-head has-background-primary-light">
                    <h1 className="modal-card-title">Submit Song Request</h1>
                </header>
                <section className="modal-card-body">
                    <form>
                        <div className="field is-horizontal">
                            <div className="field-label is-medium has-text-left">
                                <label className="label">Artist Name</label>
                            </div>
                            <div className="field-body">
                                <div className="field">
                                    <div className="control">
                                        <input
                                            required
                                            type="text"
                                            placeholder="e.g. Imagine Dragons"
                                            value={artist}
                                            onChange={(e) => setArtist(e.target.value)}
                                            className="input">
                                        </input>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="field is-horizontal">
                            <div className="field-label is-medium has-text-left">
                                <label className="label">Song Name</label>
                            </div>
                            <div className="field-body">
                                <div className="field">
                                    <div className="control">
                                        <input
                                            type="text"
                                            placeholder="e.g. Radioactive"
                                            value={song}
                                            onChange={(e) => setSong(e.target.value)}
                                            className="input"
                                            required>
                                        </input>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div className="field is-grouped is-grouped-centered mt-3">
                        <div className="control">
                            <button className="button is-primary" onClick={handleCreateMusic}>Submit</button>
                        </div>
                        <div className="control">
                            <button className="button is-danger" onClick={handleModal}>Cancel</button>
                        </div>
                    </div>
                </section>
                <footer className="modal-card-foot has-background-primary-light">
                    <p className="has-text-danger">{inputError}</p>

                </footer>
            </div>
        </div>
    );
};

export default MusicModal;
